var GoogleMapsConstructor = function(options) {
  var loadScript = function() {
    if (document.querySelector('[data-google-maps-js]')) {
      googleMapCallback();
      return;
    }

    var apiKey = document.querySelector(options.mapCanvas).dataset.apiKey;

    var script = document.createElement('script');
    script.type = 'text/javascript'; script.async = 'async';
    script.defer = 'defer';
    script.setAttribute('data-turbolinks-eval', 'false');
    script.setAttribute('data-google-maps-js', 'true');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=googleMapCallback';
    document.querySelector('head').appendChild(script);
  };

  this.init = function() {
    if(document.querySelector(options.mapCanvas)) {
      loadScript();
    }
  };
};

export default GoogleMapsConstructor;
